import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../../api';

const SLICE_NAME = 'settings';

export interface RootState {
  [SLICE_NAME]: {
    country: string;
    phone: string;
  };
}

export const getSettings = createAsyncThunk(
  'settings/getSettings',
  () => api.getSettings(),
);

export const counterSlice = createSlice({
  name: SLICE_NAME,
  initialState: {
    isForthPageEnabled: true,
    isThirdPageWarningShown: true,
  },
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(getSettings.fulfilled, (state, { payload }) => {
      state.isForthPageEnabled = payload.isForthPageEnabled;
      state.isThirdPageWarningShown = payload.isThirdPageWarningShown;
    });
  },
});

// Action creators are generated for each case reducer function
export const getAuthState = (state: RootState) => state[SLICE_NAME];

export default counterSlice.reducer;
