import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledFormControlLabel } from './StyledComponents/StyledFormControlLabel';
import { StyledCheckBoxSign } from './StyledComponents/StyledCheckBoxSign';

const CheckboxSign = () => {
  const { t } = useTranslation();

  return (
    <StyledFormControlLabel
      control={(
        <StyledCheckBoxSign
          defaultChecked
        />
      )}
      label={t('auth.form.checkBox.label')}
    />
  );
};

export default CheckboxSign;
