import { createSlice } from '@reduxjs/toolkit';
import { PT_DOMAIN, RU_DOMAIN } from '../../../data/consts';
import { getCountryByCode } from '../../../utils/countries';

export interface RootState {
  authentication: {
    country: string;
    phone: string;
    pwd: string;
    availableTranslation: string;
  };
}

const host = process.env.NODE_ENV === 'development' ? PT_DOMAIN : window.location.host;

function getCountryCode() {
  switch (host) {
  case RU_DOMAIN:
    return 'ru';
  case PT_DOMAIN:
    return 'pt';
  default:
    return 'ru';
  }
}

const countryCode = getCountryCode();

const country = getCountryByCode(countryCode === 'pt' ? 'br' : countryCode);

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    country: country?.names[countryCode === 'ru' ? 'ru' : 'en'] || '',
    phone: country?.tel_code || '',
    pwd: '',
    availableTranslation: countryCode,
  },
  reducers: {
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    clearCountry: state => {
      state.country = '';
    },
    clearPhone: state => {
      state.phone = '';
    },
    setPwd: (state, { payload }) => {
      state.pwd = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCountry, setPhone, clearCountry, clearPhone, setPwd,
} = authSlice.actions;
export const getAuthState = (state: RootState) => state.authentication;

export default authSlice.reducer;
