import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import s from './PassWord.module.scss';
import { StyledTextField } from './StyledComponents/StyledTextField';
import { StyledFormControl } from './StyledComponents/StyledFormControl';

interface Props {
  value: string,
  handleCodeChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  handleCodeSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
  isError: boolean,
  errorMessage?: string
}

const PassWord: FC<Props> = ({
  value,
  handleCodeChange,
  handleCodeSubmit,
  isError,
  errorMessage,
}) => {
  const { t } = useTranslation();

  return (
    <form
      className={s.form}
      onSubmit={handleCodeSubmit}
    >
      <StyledFormControl fullWidth>
        <StyledTextField
          className={s.telephone}
          value={value}
          onChange={handleCodeChange}
          label={t('auth.pass.form.password.label')}
          name="Password"
          type="password"
          helperText={isError && (errorMessage || t('auth.pass.form.password.error.minlength'))}
          error={isError}
          inputProps={{
            className: s.inputField,
          }}
        />
      </StyledFormControl>
    </form>
  );
};

export default PassWord;
