import { InputLabel } from '@mui/material';
import { styled } from '@mui/system';

export const StyledLabelText = styled(InputLabel)({
  '&': {
    color: 'rgb(170,170,170)',
  },
  '&.Mui-focused': {
    color: 'rgb(135,116,225)',
  },
});
