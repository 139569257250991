import React, { FC } from 'react';
import InputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';
import s from './TelephoneNumber.module.scss';
import { StyledTextField } from './StyledComponents/StyledTextField';
import { StyledFormControl } from './StyledComponents/StyledFormControl';

interface Props {
  value: string,
  isPhoneValid: boolean,
  isPhoneTouched: boolean,
  // eslint-disable-next-line no-unused-vars
  handlePhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  // eslint-disable-next-line no-unused-vars
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void,
}

const TelephoneNumber: FC<Props> = ({
  value,
  isPhoneValid,
  isPhoneTouched,
  handlePhoneChange,
  onBlur,
}) => {
  const { t } = useTranslation();

  return (
    <StyledFormControl className={s.formControl} fullWidth>
      <InputMask
        mask="+9 999 999 99 99999999999999999999999999999"
        value={value}
        onChange={handlePhoneChange}
        onBlur={onBlur}
        maskChar=" "
      >
        {/* @ts-ignore */}
        {() => (
          <StyledTextField
            id="telephone"
            InputProps={{
              className: s.telephone,
            }}
            label={t('auth.form.phone.label')}
            error={!isPhoneValid && isPhoneTouched && value?.trim()?.length > 5}
            name="telephone"
            type="tel"
          />
        )}
      </InputMask>
    </StyledFormControl>
  );
};

export default TelephoneNumber;
