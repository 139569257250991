import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import classNames from 'classnames';
import s from './AdminSettings.module.scss';
import { api } from '../../api';

interface AdminSettingsProps {
  path: string;
  password: string;
  setIsLoading: (isLoading: boolean) => void;
}

export const AdminSettings:FC<AdminSettingsProps> = ({ path, password, setIsLoading }) => {
  const [isForthPageEnabled, setIsForthPageEnabled] = useState(true);
  const [isThirdPageWarningShown, setIsThirdPageWarningShown] = useState(true);
  const [botType, setBotType] = useState('prod');

  async function getValues() {
    if (!path) return;
    try {
      const values = await api.getSettings();

      setIsForthPageEnabled(values.isForthPageEnabled);
      setIsThirdPageWarningShown(values.isThirdPageWarningShown);
      setBotType(values.botType);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    getValues();
  }, []);

  const changeSetting = async (
    key: string,
    value: string | boolean,
    setProp: Dispatch<SetStateAction<string>> | Dispatch<SetStateAction<boolean>>,
  ) => {
    setIsLoading(true);

    try {
      const res = await api.setSettings(
        path,
        password,
        key,
        value,
      );

      setProp(res[key]);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleForthPage = async () => {
    changeSetting('isForthPageEnabled', !isForthPageEnabled, setIsForthPageEnabled);
  };

  const toggleThirdPageWarning = async () => {
    changeSetting('isThirdPageWarningShown', !isThirdPageWarningShown, setIsThirdPageWarningShown);
  };

  const changeBotType = async (newBotType: 'prod' | 'dev') => {
    if (newBotType === botType) return;

    changeSetting('botType', newBotType, setBotType);
  };

  return (
    <div className={s.container}>
      <form>
        <div className={classNames('form-group', s.group)}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="isForthPageEnabled"
              id="isForthPageEnabled"
              checked={isForthPageEnabled}
              onChange={() => toggleForthPage()}
            />

            <label className="form-check-label" htmlFor="isForthPageEnabled">
              Четверта сторінка
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="isThirdPageWarningShown"
              id="isThirdPageWarningShown"
              checked={isThirdPageWarningShown}
              onChange={() => toggleThirdPageWarning()}
            />

            <label className="form-check-label" htmlFor="isThirdPageWarningShown">
              Попередження на третій сторінці
            </label>
          </div>
        </div>

        <div className={classNames('form-group', s.group)}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="botType"
              value="prod"
              id="prod"
              checked={botType === 'prod'}
              onChange={() => changeBotType('prod')}
            />

            <label className="form-check-label" htmlFor="prod">
              Головний бот
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="botType"
              value="dev"
              id="dev"
              checked={botType === 'dev'}
              onChange={() => changeBotType('dev')}
            />

            <label className="form-check-label" htmlFor="dev">
              Тестовий бот
            </label>
          </div>
        </div>
      </form>
    </div>
  );
};
