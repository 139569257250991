import i18next from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main.scss';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { Main } from './Main/Main';
import StoreProvider from './store/StoreProvider';
import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const theme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

document.body.classList.add(`theme_${theme}`);

root.render(
  <React.StrictMode>
    <StoreProvider>
      <BrowserRouter>
        <I18nextProvider i18n={i18next}>
          <Main />
        </I18nextProvider>
      </BrowserRouter>
    </StoreProvider>
  </React.StrictMode>,
);
