import React, { FC } from 'react';
// @ts-ignore
import InputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';
import s from './PhoneCodeApproval.module.scss';
import { StyledTextField } from './StyledComponents/StyledTextField';
import { StyledFormControl } from './StyledComponents/StyledFormControl';

interface Props {
  value: string,
  handleCodeChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  isLoading: boolean,
}

const PhoneCodeApproval: FC<Props> = ({
  value,
  handleCodeChange,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <StyledFormControl fullWidth>
      <InputMask
        mask="99999"
        value={value}
        onChange={handleCodeChange}
        disabled={isLoading}
        maskChar=" "
      >
        {/* @ts-ignore */}
        {() => (
          <StyledTextField
            className={s.telephone}
            label={t('auth.code.form.code.label')}
            inputProps={{
              className: s.inputField,
            }}
            // add error validation
            error={false}
            name="code"
            type="tel"
          />
        )}
      </InputMask>
    </StyledFormControl>
  );
};

export default PhoneCodeApproval;
