import { styled } from '@mui/system';
import { Select } from '@mui/material';

export const StyledSelect = styled(Select)({
  '&': {
    borderRadius: '0.75rem',
    color: '#ffffff',
    height: '54px',
  },
  '& fieldset': {
    transition: 'all 0.15s ease',
    borderColor: 'rgb(91,91,90)',
  },
  '&:hover > fieldset, &.Mui-focused > fieldset': {
    borderColor: 'rgb(135,116,225) !important',
  },
  '&.Mui-focused .custom-icon': {
    top: '1.5rem',
    transform: 'scaleY(-1) rotate(45deg)',
    borderColor: 'rgb(135,116,225)',
  },
  '> div': {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontWeight: 300,
  },
});
