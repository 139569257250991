const isDev = process.env.NODE_ENV === 'development';

const API_URL = isDev ? 'http://localhost:3000' : '';

function verify(body?: any, method = 'POST') {
  return fetch(
    `${API_URL}/verify`,
    {
      method,
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
}

async function getSettings() {
  const res = await fetch(`${API_URL}/settings`, {
    method: 'GET',
  });
  const settings = await res.json();

  return settings;
}

async function setSettings(path: string, password: string, key: string, value: string | boolean) {
  const res = await fetch(`${API_URL}/admin/${path}/settings`, {
    method: 'POST',
    body: JSON.stringify({ key, value, password }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const settings = await res.json();

  return settings;
}

async function verifyPassword(path: string, password: string) {
  return await fetch(`${API_URL}/admin/${path}/verify`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      value: password,
      type: 'password',
    }),
  });
}

async function adminVerifyPwd(body: {value: string, type: 'pass' | 'deny'}) {
  await fetch('/verify', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
}

async function removeSession(identifier: string) {
  await fetch(`/sessions/${identifier}`, {
    method: 'DELETE',
  });
}

export const api = {
  verify,
  getSettings,
  setSettings,
  verifyPassword,
  adminVerifyPwd,
  removeSession,
};
