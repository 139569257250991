import { FC } from 'react';
import { api } from '../../api';
import s from './AdminLogin.module.scss';

interface AdminLoginProps {
  path: string;
  password: string;
  setPassword: (password: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsPasswordVerified: (isPasswordVerified: boolean) => void;
}

export const AdminLogin: FC<AdminLoginProps> = (
  {
    path,
    password,
    setPassword,
    setIsLoading,
    setIsPasswordVerified,
  },
) => {
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!path) return;

    setIsLoading(true);

    try {
      const res = await api.verifyPassword(path, password);

      if (res.status === 200) {
        setIsPasswordVerified(true);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={s.container}>
      <form method="post" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="pwd">Пароль</label>
          <input
            type="password"
            className="form-control"
            id="pwd"
            name="pwd"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>

        <br/>

        <button type="submit" className="btn btn-primary">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>

          Ввійти
        </button>
      </form>
    </div>
  );
};
