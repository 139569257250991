import { styled } from '@mui/system';
import { FormControlLabel } from '@mui/material';

export const StyledFormControlLabel = styled(FormControlLabel)({
  '&': {
    height: '24px',
    marginBottom: '2.75rem',
  },
  '> span.MuiTypography-root': {
    marginLeft: '1.4rem',
  },
});
