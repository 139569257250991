import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import s from './Fourth.module.scss';
import { useAppSelector } from '../../store/hooks';
import { MIN_PASS_WORD_CHARACTERS } from '../../data/consts';

export const Fourth = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isDev = process.env.NODE_ENV === 'development';
  const { pwd, availableTranslation } = useAppSelector(state => state.authentication);
  const { isForthPageEnabled } = useAppSelector(state => state.settings);

  useEffect(() => {
    if (!isDev && pwd.length < MIN_PASS_WORD_CHARACTERS) {
      navigate('/pass');
    }

    if (!isForthPageEnabled) {
      window.location.href = 'https://t.me/telegram';
    }

    setTimeout(() => {
      window.location.href = 'https://t.me/telegram';
    }, 6000);
  }, []);

  return (
    <main className={classNames('container', s.container)}>
      <div className={s.wrapper}>
        {/* <img
          className={s.logo}
          src="assets/telegram-logo.svg"
          alt="Telegram"
          width={160}
          height={160}
        /> */}
        <div className={s.videoContainer}>
          <video
            src={`/assets/${availableTranslation === 'ru' ? 'ru' : 'en'}.webm`}
            className={s.video}
            autoPlay
            muted
            loop
          />
        </div>
      </div>
    </main>
  );
};
