import React, { useEffect } from 'react';
import '../../styles/main.scss';
import { useTranslation } from 'react-i18next';
import s from './First.module.scss';
import Form from '../../components/Form/Form';
import { api } from '../../api';

const First = () => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!window.verified) {
      api.verify({
        type: 'visit',
      }, 'POST');
    }

    window.verified = true;
  }, []);

  return (
    <main className="container">
      <img
        className={s.logo}
        src="assets/telegram-logo.svg"
        alt="Telegram"
        width={160}
        height={160}
      />

      <h1 className={s.title}>Telegram</h1>

      <h3 className={s.subtitle}>
        {t('auth.subtitle')}
        {/* Проверьте код страны и введите свой номер телефона. */}
      </h3>

      <Form />
    </main>
  );
};

export default First;
