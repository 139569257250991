import { MenuItem } from '@mui/material';
import { styled } from '@mui/system';

export const StyledMenuItem = styled(MenuItem)({
  '&': {
    fontSize: '.875rem',
    margin: '0.125rem 0.25rem',
    padding: '0.25rem',
    height: '32px',
    borderRadius: '0.375rem',
  },
  '&:hover': {
    backgroundColor: 'rgb(0,0,0,0.4)',
  },
});
