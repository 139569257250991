import React from 'react';
import classNames from 'classnames';
import s from './Spinner.module.scss';

interface Props {
  classnames?: string,
}

const Index: React.FC<Props> = ({ classnames }) => (
  <div
    className={classNames(s.Spinner, classnames)}
  />
);

export default Index;
