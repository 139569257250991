import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import s from './Button.module.scss';
import Spinner from '../Spinner';

interface Props {
  isLoading: boolean;
  onClick: (e: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => Promise<void> | void;
  type?: 'button' | 'submit';
}

export const Button: FC<Props> = ({ onClick, isLoading = false, type = 'button' }) => {
  const { t, i18n } = useTranslation();

  return (
    <button
      className={classNames(
        s.submitBtn,
        {
          [s.submitBtn_loading]: isLoading,
        },
      )}
      type="submit"
      onClick={onClick}
    >
      {
        !isLoading ? (
          <>
            {t('auth.form.next')}
          </>
        ) : (
          <>
            {t('auth.form.loading').toUpperCase()}

            <Spinner classnames={s.wait} />
          </>
        )
      }
    </button>
  );
};
