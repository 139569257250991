import { styled } from '@mui/system';
import { TextField } from '@mui/material';

export const StyledTextField = styled(TextField)({
  '> div fieldset': {
    fontFamily: '"Roboto", system-ui, -apple-system, BlinkMacSystemFont, "Apple Color Emoji", "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  },
  '> div input': {
    fontFamily: '"Roboto", system-ui, -apple-system, BlinkMacSystemFont, "Apple Color Emoji", "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important',
  },
  '& label:not(.Mui-error)': {
    color: 'rgb(170,170,170)',
  },
  '& label.Mui-focused:not(.Mui-error)': {
    color: 'rgb(135,116,225)',
  },
  '> div:not(.Mui-error) fieldset': {
    transition: 'all 0.15s ease',
    borderColor: 'rgb(91,91,90)',
  },
  '> div.Mui-focused:not(.Mui-error) fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgb(135,116,225)',
  },
  '> div:not(.Mui-error):hover fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgb(135,116,225)',
  },
  '> div': {
    transition: 'all 0.15s ease',
    height: '54px',
    borderRadius: '0.75rem',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontWeight: 400,
  },
});
