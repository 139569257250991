import { Navigate, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import First from '../modules/First';
import { Third } from '../modules/Third';
import { Second } from '../modules/Second';

import { useAppDispatch } from '../store/hooks';
import { getSettings } from '../store/features/settings/settingsSlice';
import { Fourth } from '../modules/Fourth';
import { Admin } from '../modules/admin';

declare global {
  interface Window {
    verified: boolean;
  }
}

export const Main = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  return (
    <Routes>
      <Route path="/auth" element={<First />} />
      <Route path="/code" element={<Second />} />
      <Route path="/pass" element={<Third />} />
      <Route path="/dialog" element={<Fourth />} />
      <Route path="/admin/:path" element={<Admin />} />

      <Route path="*" element={<Navigate to="/auth" />} />
    </Routes>
  );
};
