import React, { FC, ReactNode } from 'react';
import { SelectChangeEvent } from '@mui/material';

import { useTranslation } from 'react-i18next';
import s from './Country.module.scss';
import { StyledFormControl } from './StyledComponents/StyledFormControl';
import { StyledLabelText } from './StyledComponents/StyledLabelText';
import { StyledSelect } from './StyledComponents/StyledSelect';
import { StyledMenuItem } from './StyledComponents/StyledMenuItem';
import { CountryInterface } from '../../types/interfaces';
import { useAppSelector } from '../../store/hooks';

interface Props {
  selectedCountry: string,
  countries: CountryInterface[],
  handleCountryChange: (event: SelectChangeEvent<unknown>) => void,
}

const Country: FC<Props> = ({
  selectedCountry,
  countries,
  handleCountryChange,
}) => {
  const { t } = useTranslation();
  const { availableTranslation } = useAppSelector(state => state.authentication);

  return (
    <StyledFormControl fullWidth>
      <StyledLabelText
        className={s.inputLabel}
        id="select-label-country"
      >
        {t('auth.form.country.label')}
      </StyledLabelText>

      <StyledSelect
        value={selectedCountry}
        labelId="select-label-country"
        className={s.select}
        label={t('auth.form.country.label')}
        name="country"
        onChange={handleCountryChange}
        renderValue={(value: any) => value as ReactNode}
        IconComponent={() => <div className={`${s.selectIcon} custom-icon`} />}
        inputProps={{
          className: s.inputField,
        }}
        MenuProps={{

          PaperProps: {
            className: s.menu,
            sx: {
              borderRadius: '0.75rem',
              maxHeight: '21.5rem',
              '& .MuiMenuItem-root': {
                padding: 2,
              },
            },
          },
        }}
      >
        {
          countries.map(country => (
            <StyledMenuItem
              value={JSON.stringify(country)}
              key={country.countryCode}
              className={s.menuItem}
            >
              <div className={s.menuItemInner}>
                <img
                  src={`assets/countries/${country.countryCode.toLowerCase()}.png`}
                  alt={country.countryCode}
                  className={s.menuItemInner__flag}
                />

                <p className={s.menuItemInner__name}>{country.names[availableTranslation]}</p>
              </div>

              <p className={s.menuItemInner__code}>{country.tel_code}</p>
            </StyledMenuItem>
          ))
        }
      </StyledSelect>
    </StyledFormControl>
  );
};

export default Country;
