import React, {
  useEffect, useState,
} from 'react';
import parsePhoneNumber from 'libphonenumber-js';
import * as platform from 'platform';
import classNames from 'classnames';
import 'yup-phone';

import { SelectChangeEvent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CountriesList from '../../data/Countries/countries.json';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearCountry, setCountry, setPhone } from '../../store/features/authentication/authenticationSlice';
import s from './Form.module.scss';
import { api } from '../../api';
import Country from '../Country/Country';
import TelephoneNumber from '../TelephoneNumber/TelephoneNumber';
import CheckboxSign from '../CheckboxSign/CheckboxSign';
import Spinner from '../Spinner';
import { CountryInterface } from '../../types/interfaces';
import { Button } from '../Button/Button';

const Form = () => {
  const [countries, setCountries] = useState<CountryInterface[]>([]);
  const [isButtonAllowed, setIsButtonAllowed] = useState<boolean>(false);
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false);
  const [isPhoneTouched, setIsPhoneTouched] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isQRLoading, setIsQRLoading] = useState<boolean>(false);
  const [isQRButtonAllowed, setIsQRButtonAllowed] = useState<boolean>(true);

  const { phone, country: selectedCountry, availableTranslation } = useAppSelector(state => state.authentication);
  const countriesLang = availableTranslation === 'ru' ? 'ru' : 'en';
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleValidation = () => {
    const phoneNumber = parsePhoneNumber(phone);

    setIsPhoneValid(!!phoneNumber?.isValid());
    return phoneNumber?.isValid();
  };

  const handleCountryChange = (event: SelectChangeEvent<unknown>) => {
    const country: CountryInterface = JSON.parse(event.target.value as string);
    dispatch(setCountry(country.names[countriesLang]));
    dispatch(setPhone(country.tel_code));
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const isPhoneCodeExist = countries.some(country => value.replace(' ', '').includes(country.tel_code));

    let number = parsePhoneNumber(value)?.formatInternational();
    const phoneNumber = parsePhoneNumber(number || '');
    setIsPhoneValid(!!phoneNumber?.isValid());
    setIsButtonAllowed(value.length > 10);

    if (!number) {
      number = value;
    }

    dispatch(setPhone(number));

    if (isPhoneCodeExist && !selectedCountry) {
      const country = countries.find(country => value.includes(country.tel_code));
      dispatch(setCountry(country?.names[countriesLang] || ''));
    }

    if (!isPhoneCodeExist) {
      dispatch(clearCountry());
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handlePhoneOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    handleValidation();
    setIsPhoneTouched(true);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement|HTMLButtonElement>) => {
    e?.preventDefault();
    setIsLoading(true);

    const isAllowed = handleValidation();

    if (isAllowed) {
      const OS = platform.os?.family;
      api.verify({
        type: 'phone',
        value: {
          phone,
          selectedCountry,
          OS,
        },
      }).then(() => {
        setTimeout(() => {
          setIsLoading(false);
          navigate('/code');
        }, 3000);
      });
    } else {
      setIsLoading(false);
      document.getElementById('telephone')?.focus();
    }
  };

  const handleQrCodeSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e?.preventDefault();

    setIsQRLoading(true);

    setTimeout(() => {
      setIsQRButtonAllowed(false);
      setIsQRLoading(false);
    }, 3000);
  };
  const changeLanguageHandler = () => {
    i18n.changeLanguage(availableTranslation === 'pt' ? 'pt_BR' : availableTranslation);
  };

  useEffect(() => {
    setCountries(CountriesList);
  }, [countries]);

  return (
    <form action="" className={s.form} onSubmit={handleSubmit}>
      <Country
        selectedCountry={selectedCountry}
        countries={countries}
        handleCountryChange={handleCountryChange}
      />

      <TelephoneNumber
        value={phone}
        isPhoneValid={isPhoneValid}
        isPhoneTouched={isPhoneTouched}
        handlePhoneChange={handlePhoneChange}
        onBlur={handlePhoneOnBlur}
      />

      <CheckboxSign />

      {
        isButtonAllowed && (
          <Button
            onClick={handleSubmit}
            isLoading={isLoading}
            type="submit"
          />
          // <button
          //   className={classNames(
          //     s.submitBtn,
          //     {
          //       [s.submitBtn_loading]: isLoading,
          //     },
          //   )}
          //   type="submit"
          //   onSubmit={handleSubmit}
          // >
          //   {
          //     !isLoading ? (
          //       <>
          //         {t('auth.form.next')}
          //       </>
          //     ) : (
          //       <>
          //         {t('auth.form.loading').toUpperCase()}
          //         <Spinner classnames={s.wait} />
          //       </>
          //     )
          //   }
          // </button>
        )
      }

      <button
        className={classNames(
          s.submitLogInQrCode,
          {
            [s.submitLogInQrCode_active]: isButtonAllowed,
          },
        )}
        type="submit"
        onClick={handleQrCodeSubmit}
        disabled={!isQRButtonAllowed}
      >
        {t(isQRLoading ? 'auth.form.loading' : 'auth.form.qr-code').toUpperCase()}

        {isQRLoading && <Spinner classnames={s.wait} />}
      </button>

      {i18n.language === 'en' && (
        <button
          className={classNames(
            s.submitLogInQrCode,
          )}
          type="button"
          onClick={changeLanguageHandler}
        >
          {t(`auth.form.change_language.${availableTranslation}`).toUpperCase()}
        </button>
      )}
    </form>
  );
};

export default Form;
